import axios, { AxiosRequestConfig } from 'axios';

export default class HttpRequest {
  static async get(URL: string, options?: AxiosRequestConfig) {
    try {
      const response = await axios.get(URL, options);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async post(
    URL: string,
    body: any = {},
    options: AxiosRequestConfig = {},
  ) {
    try {
      const response = await axios.post(URL, body, options);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async patch(
    URL: string,
    body: any = {},
    options: AxiosRequestConfig = {},
  ) {
    try {
      const response = await axios.patch(URL, body, options);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { AppBar as RAAppBar } from 'react-admin';

import style from './style';

const useStyles = makeStyles(style);

const AppBar = (props: any) => {
  const classes = useStyles();
  return (
    <RAAppBar {...props} elevation={1} color="primary">
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.centerTitle}>ZD SHOPPING</span>
      <span className={classes.spacer} />
    </RAAppBar>
  );
};

export default AppBar;

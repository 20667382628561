/* eslint @typescript-eslint/ban-ts-comment: "off" */
// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles';
import ProductsIcon from '@material-ui/icons/Store';
import classnames from 'classnames';
import * as React from 'react';
import { MenuItemLink, MenuProps, ReduxState } from 'react-admin';
import { useSelector } from 'react-redux';

import style from './style';

const useStyles = makeStyles(style);

const Menu = ({ dense = false }: MenuProps) => {
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  const classes = useStyles();

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      <MenuItemLink
        to={{
          pathname: '/products',
          state: { _scrollToTop: false },
        }}
        primaryText={'Produtos'}
        leftIcon={<ProductsIcon />}
        dense={dense}
      />
    </div>
  );
};

export default Menu;

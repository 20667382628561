import env from 'config/env';
import { AuthProvider } from 'react-admin';
import HttpRequest from 'services/HttpRequest';
import LocalSession from 'services/LocalSession';

const authProvider: AuthProvider = {
  // authentication
  login: async (params) => {
    if (LocalSession.check('accessToken')) {
      return Promise.resolve();
    }
    const response = await HttpRequest.post(env.AUTH_URL, {
      idToken: params.tokenId,
    });
    LocalSession.set('accessToken', response.accessToken);
    LocalSession.set('profile', response.profile);
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      LocalSession.remove('accessToken');
      LocalSession.remove('profile');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    if (LocalSession.check('accessToken')) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    LocalSession.clear();
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, name, picture } = LocalSession.get('profile');
      return Promise.resolve({ id, fullName: name, avatar: picture });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
  getPermissions: (params) => {
    console.log(params);
    if (LocalSession.check('accessToken')) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
};

export default authProvider;

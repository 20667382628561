import './style.css';

import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import env from 'config/env';
import React, { useCallback, useState } from 'react';
import { useAuthenticated, useNotify } from 'react-admin';
import Dropzone from 'react-dropzone';
import HttpRequest from 'services/HttpRequest';
import LocalSession from 'services/LocalSession';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Products() {
  useAuthenticated();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const notifyHook = useNotify();
  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    // Do something with the files
    if (acceptedFiles && acceptedFiles.length > 0) {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      setLoading(true);
      HttpRequest.patch(`${env.API_URL}/products/sync_by_file`, formData, {
        timeout: 300000,
        headers: {
          Authorization: `Bearer ${LocalSession.get('accessToken')}`,
        },
      })
        .then((result: any) => {
          console.log(result);
          notifyHook('Produtos Atualizados com sucesso', 'success');
        })
        .catch((error) => {
          notifyHook(
            `Erro ao atualizar os produtos ${error.toString()}`,
            'error',
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Card style={{ width: '100%' }}>
        <CardContent>
          {loading && (
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Adicione o arquivo xls para atualizar o estoque dos produtos na
              Wix
            </Typography>
          </Grid>
          <Dropzone onDrop={onDrop} accept="application/vnd.ms-excel">
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Adicione o arquivo XLS aqui</p>
              </div>
            )}
          </Dropzone>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Products;

import env from 'config/env';
import simpleRestProvider from 'ra-data-simple-rest';
import {
  DataProvider,
  fetchUtils,
  GetListParams,
  GetListResult,
} from 'react-admin';
import LocalSession from 'services/LocalSession';

const fetchJson = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('react-admin-agent', true);
  if (LocalSession.check('accessToken')) {
    options.user = {
      authenticated: true,
      token: `Bearer ${LocalSession.get('accessToken')}`,
    };
  }
  return fetchUtils.fetchJson(url, options);
};

const simpleProvider = simpleRestProvider(env.API_URL, fetchJson);

const nestJsDataProvider: DataProvider = {
  ...simpleProvider,
  getList: (
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<any>> => {
    return simpleProvider.getList(resource, params);
  },
};

export default nestJsDataProvider;

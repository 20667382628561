import AuthProvider from 'config/Providers/AuthProvider';
import DataProvider from 'config/Providers/DataProvider';
import themes from 'config/themes';
import React from 'react';
import { Admin, Resource } from 'react-admin';

import layout from './layout';
import pages from './pages';
import Login from './pages/Login/index';

function App() {
  return (
    <Admin
      theme={themes}
      authProvider={AuthProvider}
      loginPage={Login}
      dataProvider={DataProvider}
      layout={layout}
      customRoutes={pages}
      disableTelemetry
    >
      <Resource name="products" />
    </Admin>
  );
}

export default App;

import {
  Card,
  CardContent,
  CardMedia,
  createStyles,
  createTheme,
  Grid,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';
import logo from 'assets/images/logo_image.png';
import env from 'config/env';
import React from 'react';
import { defaultTheme, Notification, useLogin, useNotify } from 'react-admin';
import { GoogleLogin } from 'react-google-login';

import style from './style';

interface LoginProps {
  theme?: any;
}

function Login(props: LoginProps) {
  const useStyles = makeStyles(
    createStyles(style(props.theme || defaultTheme)),
  );
  const classes = useStyles();

  const loginHook = useLogin();
  const notifyHook = useNotify();

  const handleLogin = async (googleData: any) => {
    loginHook(googleData).catch(() => {
      notifyHook('Credenciais erradas', 'error');
    });
  };

  const handleFailure = async (result: any) => {
    console.log(result);
    notifyHook('Erro ao realizar login');
  };

  return (
    <ThemeProvider theme={createTheme(props.theme)}>
      <Grid
        container
        className={classes.holder}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={10} sm={10} md={6} container justify="center">
          <Card elevation={3} className={classes.card}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} container justify="center">
                  <CardMedia
                    className={classes.logo}
                    component="img"
                    alt="logo"
                    image={logo}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="center">
                    <Grid item>
                      <GoogleLogin
                        clientId={env.GOOGLE_CLIENT_ID}
                        buttonText="Faça login com Google"
                        onSuccess={handleLogin}
                        onFailure={handleFailure}
                        cookiePolicy={'single_host_origin'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Notification />
      </Grid>
    </ThemeProvider>
  );
}

export default Login;

import {
  blue as MuiBlue,
  grey as MuiGrey,
  red as MuiRed,
} from '@material-ui/core/colors';

export const white = {
  dark: '#b2b2b2',
  main: '#ffffff',
  light: '#ffffff',
  contrastColor: '#000',
};

export const grey = MuiGrey[500];

export const zdBlue = {
  dark: '#152c56',
  main: '#1E3F7B',
  light: '#4b6595',
  contrastColor: '#fff',
};

export const blue = MuiBlue[500];

export const red = MuiRed[500];

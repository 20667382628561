import { Theme } from '@material-ui/core';

export default (theme: Theme) => ({
  holder: {
    background: `linear-gradient(
            ${theme.palette.primary.main} 50% 50%,
            ${theme.palette.secondary.main} 50%)`,
    height: '100%',
    minHeight: '100vh',
  },
  card: {
    minWidth: 250,
    minHeight: 150,
    width: '100%',
    maxWidth: 700,
  },
  logo: {
    marginTop: theme.spacing(4),
    width: 300,
  },
});

import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core/styles';
import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';

import { white, zdBlue } from './colors/index';

const theme = merge({}, defaultTheme, {
  palette: {
    primary: zdBlue,
    secondary: white,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default createTheme(theme);

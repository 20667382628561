import { createStyles } from '@material-ui/styles';

export default () =>
  createStyles({
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    spacer: {
      flex: 1,
    },
    centerTitle: {
      fontWeight: 'bold',
    },
  });

import { Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

export default (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: 200,
    },
    closed: {
      width: 55,
    },
  });
